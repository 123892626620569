<template>
  <div>
    <div class="center-content">
      <div class="hello">
        <h1>Bald stehen wir Ihnen professionell zur Verfügung.</h1>
        <br>
        <h2>S&N Services</h2>
        <img alt="Vue logo" src="./assets/logo.png" class="logo">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    document.title = 'S&N Services'; // Set the title on component mount
  }
}
</script>

<style scoped>
.center-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Adjust the height as needed */
}

.hello {
  text-align: center;
}

.logo {
  max-width: 50%; /* Adjust the percentage as needed */
  display: block;
  margin: 0 auto;
}

/* Your existing styles */
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
